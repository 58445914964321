<template>
  <div class="newSeeds">
    <!-- title -->
    <div class="title">
      <div class="title-top">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-circle-plus-outline"
          @click="addMessage"
          >新增</el-button
        >
        <div class="el-title-item">
          <el-form ref="form" :model="logisticsForm" label-width="80px">
            <el-form-item label="关键字">
              <el-input
                v-model="logisticsForm.names"
                placeholder="请输入关键字"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="title-bottom">
        <div class="title-bottom-left">
          <el-button @click="refresh" icon="el-icon-refresh-right" size="small"
            >刷新</el-button
          >
        </div>
        <div class="title-bottom-right">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="small" @click="removeForm"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <!-- table -->
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column
        width="100"
        label="序号"
        type="index"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="名称"
        prop="name"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="关键字"
        prop="keyword"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="介绍"
        prop="introduce"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="作者"
        prop="author"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="updateClick(scope.$index, scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="del(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <el-dialog
      title=""
      :visible.sync="updateForm"
      width="30vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative;">
        <span class="title-name"></span>
        <span class="title-age">{{ boxPrompt }}</span>
        <span
          style="position: absolute;top: 50%;transform: translateY(-50%);right: 0px;font-size: 2.22vh;"
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="upTableData"
          :rules="rules"
          :model="upTableData"
          label-width="7vw"
        >
          <el-form-item label="名称" prop="name">
            <el-input
              v-model.trim="upTableData.name"
              placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="关键字" prop="keyword">
            <el-input
              v-model.trim="upTableData.keyword"
              placeholder="请输入关键字"
            ></el-input>
          </el-form-item>
          <el-form-item label="介绍" prop="introduce">
            <el-input
              type="textarea"
              v-model="upTableData.introduce"
              placeholder="请输入介绍"
            ></el-input>
          </el-form-item>
          <el-form-item label="作者" prop="author">
            <el-input
              v-model.trim="upTableData.author"
              placeholder="请输入作者"
            ></el-input>
          </el-form-item>
          <el-form-item size="large">
            <el-button
              class="saveBtn"
              type="primary"
              @click="saveForm('upTableData')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      upTableData: {
        name: "",
        keyword: "",
        introduce: "",
        author: "",
      },
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      searchStatus: false,
      logisticsForm: {
        names: "",
        region: -1,
      },
      updateForm: false,
      boxPrompt: "",
      rules: {
        name: {
          required: true,
          message: "名称不可为空",
          trigger: "blur",
        },
        keyword: {
          required: true,
          message: "关键字不能为空",
          trigger: "blur",
        },
        introduce: {
          required: true,
          message: "介绍不能为空",
          trigger: "blur",
        },
        author: {
          required: true,
          message: "作者不能为空",
          trigger: "blur",
        },
      },
      index: 0,
    };
  },
  mounted() {
    this.requrstList();
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.size = val;
      this.requrstList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.requrstList();
    },
    //请求列表数据
    requrstList(where) {
      this.$get("/agriculturalKnowledge/getAgriculturalKnowledges", {
        page: this.page,
        size: this.size,
        keyword: where || "",
      }).then((res) => {
        // //console.log(res);
        if (res.data.state == "success") {
          this.tableData = [];
          this.total = res.data.count;
          res.data.datas.forEach((item) => {
            if (item.del !== 1) {
              this.tableData.push(item);
              // //console.log(this.tableData);
            }
          });
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    // 编辑
    updateClick(param1, param2) {
      this.boxPrompt = "编辑";
      this.updateForm = true;
      this.$nextTick(() => {
        this.upTableData = JSON.parse(JSON.stringify(param2));
      });
    },
    // 新增
    addMessage() {
      this.boxPrompt = "新增";
      this.updateForm = true;
      if (this.$refs["upTableData"] !== undefined) {
        this.$nextTick(() => {
          this.upTableData = this.upTableData;
          this.$refs["upTableData"].resetFields();
        });
      }
    },
    //删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/agriculturalKnowledge/deleteAgriculturaKnowLedge", {
            id: item.id,
          }).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.requrstList();
            } else {
              this.$message.info(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //保存
    saveForm() {
      let url = "/agriculturalKnowledge/addOrUpdateAgriculturalKnowledge";
      let updata = this.upTableData;
      this.$refs["upTableData"].validate((valid) => {
        if (valid) {
          this.$get(url, updata).then((res) => {
            this.updateForm = false;
            //console.log(res);
            if (res.data.state == "success") {
              this.requrstList();
              this.$message.success("操作成功");
            } else {
              this.$message.info(res.data.msg);
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    //刷新
    refresh() {
      this.loading = true;
      if (!this.searchStatus) {
        this.requrstList();
      } else {
        this.searchs();
      }
    },
    //搜索
    search() {
      let name = this.logisticsForm.names;
      this.page = 1;
      this.loading = true;
      this.searchStatus = true;
      if (name == "" || !name) {
        this.searchStatus = false;
      }
      this.requrstList(name);
    },
    //重置
    removeForm() {
      this.loading = true;
      this.logisticsForm.names = "";
      this.logisticsForm.region = -1;
      this.requrstList();
    },
  },
};
</script>
<style scoped lang="less">
@import "@/style/newBackTable.less";
.operation {
  display: flex;
  justify-content: center;
}
</style>
